import React, { useState } from "react";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { FormattedMessage, navigate } from "gatsby-plugin-intl";

import InlineFormError from "../../form/InlineFormError";
import useCreateNewUserStore from "./useNewUserStore";
import { doesUserExist, inviteUser } from "../../../services/userManagementService";
import { Spinner } from "react-bootstrap";
import { QueryClient, useQueryClient } from "@tanstack/react-query";

const Step3 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient()
  const {
    firstName,
    lastName,
    email,
    roles,
    sports,
    companyName,
    jobTitle,
    department,
    setExpiryDate,
    setMagicLink,
  } = useCreateNewUserStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ numberOfDays: number }>({
    // validate on submit - button must be enabled for this to work
    mode: "onSubmit",
    // after submit, revalidate form when user completes a field
    reValidateMode: "onBlur",
    defaultValues: { numberOfDays: 7 },
  });

  const onSubmit = (data: { numberOfDays: number }) => {
    const { numberOfDays } = data;
    const today = dayjs();
    const expiryDateInput = today.add(numberOfDays, "day");
    const expiryDateISOString = expiryDateInput.toISOString();
    setExpiryDate(expiryDateISOString);

    if (
      firstName &&
      lastName &&
      email &&
      roles &&
      sports &&
      expiryDateISOString &&
      !isLoading
    ) {
      setIsLoading(true);
      const roleStrings = roles.map((role) => role?.value);
      doesUserExist(email)
      .then((response) => {
        const { userExists, userDetails, userId } = response.data;
          inviteUser({
            firstName,
            lastName,
            email,
            roles: roleStrings,
            sports,
            companyName,
            jobTitle,
            department,
            expiryDate: expiryDateISOString,
            userExists,
            userId,
            displayName: userDetails?.displayName || undefined
          }
          ).then((response) => {
            if (response.data && response.data.magicLink) {
              setMagicLink(response.data.magicLink);
              queryClient.invalidateQueries({queryKey: ["all-users"], exact: true})
              navigate("/user-management/copy-user-details/");
            }
          });
       
      })
      .catch((error) => {
        console.error(error);
      });
      
    }
  };

  return (
    <section className="row">
      <div className="col-12">
        <h2>Step 3 of 3</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="mb-4"
        >
          <div className="mb-3">
            <label htmlFor="numberOfDays" className="form-label">
              Set the link expiry period in days
            </label>
            <div className="form-text">Choose a value between 1 and 21</div>
            <div className="w-25">
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  {...register("numberOfDays", {
                    required: true,
                    min: 1,
                    max: 21,
                  })}
                />
                <span className="input-group-text">days</span>
              </div>
            </div>
            {errors.numberOfDays?.type === "required" && (
              <InlineFormError message="Link expiry period is required" />
            )}
            {errors.numberOfDays?.type === "min" && (
              <InlineFormError message="Link expiry period must be greater than 1 day" />
            )}
            {errors.numberOfDays?.type === "max" && (
              <InlineFormError message="Link expiry period must be less than 21 days" />
            )}
          </div>
          <button className="btn btn-primary" type="submit">
            {isLoading && (
              <Spinner
                animation="border"
                variant="light"
                role="status"
                size="sm"
                className="me-2"
              >
                <span className="visually-hidden">
                  <FormattedMessage id="loading" />
                </span>
              </Spinner>
            )}
            Invite new User
          </button>
        </form>
      </div>
    </section>
  );
};

export default Step3;
